/**
 * ====================================================================================================
 * Style
 *
 * Note: It's better for package size to inject Markers as HTML represented as a string. This is the
 * reason that we're passing down the style by className into the markers and info windows. This isn't
 * a pattern to use elsewhere in the code. BEWARE, this code is special.
 * ====================================================================================================
 */

import styled, { css, keyframes } from "styled-components";
import theme from "theme";

const markerPulsarSlow = keyframes`
from {
    opacity: 0.65;
    transform: translate(-50%, -50%) scale(.1)
}
to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1)
}`;

const markerPulsarFast = keyframes`
0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(.1)
}
24%, 90% {
    opacity:0
}
25% {
    opacity: 0.65;
    transform: translate(-50%, -50%) scale(.1)
}
100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1)
}`;

export const markerAnimation = css`
	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background: var(--accent);
		content: "";
		animation: ${markerPulsarSlow} 1.7s infinite ease-out;
	}

	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		transform: translate(-50%, -50%);
		border-radius: 50%;
		background: var(--accent);
		content: "";
		animation: ${markerPulsarFast} 1.7s infinite ease-out;
	}
`;

export const GoogleMapStyleWrapper = styled.div`
    /* actual map styles */
    width: 100%;
    height: 100%;

    /** default for alert markers */
    .samdesk-marker-base {
        /* --zoomScaleFactor and --ageScaleFactor are both percentages so when we multiply them they will compound. */
        scale: calc(var(--zoomScaleFactor, 1) * var(--ageScaleFactor, 1));

        &:hover {
            ${markerAnimation}
        }
    }

    &[data-zoomLevel='region'] {
        --zoomScaleFactor: 0.6;
    }
    &[data-zoomLevel='country'] {
        --zoomScaleFactor: 0.7;
    }
    &[data-zoomLevel='state'] {
        --zoomScaleFactor: 0.9;
    }
    &[data-zoomLevel='city'] {
        --zoomScaleFactor: 1;
    }
    &[data-zoomLevel='street']
        --zoomScaleFactor: 1.2;
    }


    /** 3 Hour old alert markers */
    .samdesk-marker-old {
        opacity: 0.9;
        --ageScaleFactor: 0.8;
    }

    /** 6 Hour old alert markers */
    .samdesk-marker-older {
        opacity: 0.8;
        --ageScaleFactor: 0.6;
    }

    .forced-hover {
        ${markerAnimation}
    }

    /** map notes */
    .map-notes {
        .map-note-wrapper {
            display: flex;
            align-items: center;
            padding: 8px;
            margin: 10px;
            max-height: 36px;
            min-height: 36px;
            max-width: 250px;
            min-width: 250px;
            border-radius: 4px;
            background-color: ${theme.palette.grey[700]};

            .map-note-dot {
                min-height: 8px;
                max-height: 8px;
                min-width: 8px;
                max-width: 8px;
                border-radius: 50px;
                margin-right: 8px;
            }

            .map-note-text {
                font-size: 12px;
                color: ${theme.palette.text.secondary};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    /** map buttons */
    /** zoom buttons container */
    .samdesk-zoom-controls {
        display: flex;
        flex-direction: column;
        margin: 1rem;
        background: ${theme.palette.grey[800]};
        border: 1px solid ${theme.palette.grey[500]};
        border-radius: 4px;

        .samdesk-zoom-button {
            cursor: pointer;
            padding: 0.25rem;

            &:first-child {
                border-bottom: 1px solid ${theme.palette.grey[500]};
            }
            &:hover {
                svg {
                    fill: ${theme.palette.text.primary};
                }
            }
        }

        /* .samdesk-zoom-button {
            font-family: ${theme.typography.fontFamily};
            position: relative;
            height: 32px;
            background-color: ${theme.palette.grey[800]};
            cursor: pointer;
            text-align: center;
            color: ${theme.palette.text.primary};
            font-size: 14px;
            word-spacing: normal;
            padding: 7px 11px;
            text-transform: uppercase;

            &:not(:last-child):after {
                content: '';
                position: absolute;
                right: 0;
                top: 6px;
                bottom: 6px;
                width: 1px;
                background-color: ${theme.palette.background.default};
            }

            &:hover:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 6px;
                right: 6px;
                height: 1px;
                background-color: ${theme.palette.primary.main};
                transition: all 80ms ease-in-out;
            }

        .samdesk-zoom-button.selected {
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 6px;
                right: 6px;
                height: 3px;
                background-color: ${theme.palette.primary.main};
                transition: all 80ms ease-in-out;
            }
        } */
    }

    /** location indicator */
    .samdesk-location-indicator {
        height: 32px;
        background: ${theme.palette.grey[800]};
        font-size: 14px;
        color: ${theme.palette.text.primary};
        padding: 8px;
        margin: 1rem;
    }
`;

export default GoogleMapStyleWrapper;
