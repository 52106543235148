// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	/* TODO: add new font-families to index and remove "source sans pro" */
	--font-family-heading: "Source Sans Pro", "Source Sans 3", Helvetica, Arial,
		sans-serif;
	--font-family-body: "Open Sans", "Source Sans Pro", Helvetica, Arial,
		sans-serif;

	--font-size-minuscule: calc(var(--base-unit) * 0.625); /* 10px */
	--font-size-smallest: calc(var(--base-unit) * 0.75); /* 12px */
	--font-size-small: calc(var(--base-unit) * 0.9375); /* 15px */
	--font-size-base: calc(var(--base-unit) * 1); /* 16px */
	--font-size-large: calc(var(--base-unit) * 1.125); /* 18px */
	--font-size-largest: calc(var(--base-unit) * 1.25); /* 20px */
	--font-size-extravagant: calc(var(--base-unit) * 1.5); /* 24px */

	--font-weight-base: 300;
	/* No font-weight 500 on Source Sans Pro - 600 a little heavy for headings - update when adding new font families */
	--font-weight-bold: 400;

	--font-line-height-small: 1.3;
	--font-line-height-base: 1.5;
	--font-line-height-none: none;

	--font-letter-spacing: calc(var(--base-unit) / 64);
}
`, "",{"version":3,"sources":["webpack://./../../packages/design/src/typography.css"],"names":[],"mappings":"AAAA;CACC,sEAAsE;CACtE;YACW;CACX;YACW;;CAEX,qDAAqD,EAAE,SAAS;CAChE,mDAAmD,EAAE,SAAS;CAC9D,kDAAkD,EAAE,SAAS;CAC7D,4CAA4C,EAAE,SAAS;CACvD,iDAAiD,EAAE,SAAS;CAC5D,kDAAkD,EAAE,SAAS;CAC7D,qDAAqD,EAAE,SAAS;;CAEhE,uBAAuB;CACvB,mHAAmH;CACnH,uBAAuB;;CAEvB,6BAA6B;CAC7B,4BAA4B;CAC5B,6BAA6B;;CAE7B,kDAAkD;AACnD","sourcesContent":[":root {\n\t/* TODO: add new font-families to index and remove \"source sans pro\" */\n\t--font-family-heading: \"Source Sans Pro\", \"Source Sans 3\", Helvetica, Arial,\n\t\tsans-serif;\n\t--font-family-body: \"Open Sans\", \"Source Sans Pro\", Helvetica, Arial,\n\t\tsans-serif;\n\n\t--font-size-minuscule: calc(var(--base-unit) * 0.625); /* 10px */\n\t--font-size-smallest: calc(var(--base-unit) * 0.75); /* 12px */\n\t--font-size-small: calc(var(--base-unit) * 0.9375); /* 15px */\n\t--font-size-base: calc(var(--base-unit) * 1); /* 16px */\n\t--font-size-large: calc(var(--base-unit) * 1.125); /* 18px */\n\t--font-size-largest: calc(var(--base-unit) * 1.25); /* 20px */\n\t--font-size-extravagant: calc(var(--base-unit) * 1.5); /* 24px */\n\n\t--font-weight-base: 300;\n\t/* No font-weight 500 on Source Sans Pro - 600 a little heavy for headings - update when adding new font families */\n\t--font-weight-bold: 400;\n\n\t--font-line-height-small: 1.3;\n\t--font-line-height-base: 1.5;\n\t--font-line-height-none: none;\n\n\t--font-letter-spacing: calc(var(--base-unit) / 64);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
