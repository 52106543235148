/** home is the same as what's at the home route (/) which is the signed in route and outlet for all signed in routes */
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import { Box, CircularProgress } from "@mui/material";
import { layout } from "common/layoutDefinitions";
import { ReactElement, Suspense, lazy } from "react";
import DisabledAccount from "router/DisabledAccount";
import CenteredCircularProgress from "ui-library/CenteredCircularProgress";
import EnhancedOutlet from "ui-library/EnhancedOutlet";
import NavBar from "ui-library/nav-bar/NavBar";
import { withErrorBoundary } from "utils/ErrorBoundary";
import { ErrorComponent } from "utils/ErrorComponent";

import { SamdeskIntercomProvider } from "utils/SamdeskIntercomProvider";
import useController from "./useController";

const IncidentViewModal = lazy(() =>
	import("ui-library/IncidentViewModal").then((module) => ({
		default: module.IncidentViewModal,
	}))
);
const SearchModal = lazy(() => import("ui-library/search/SearchModal"));

const Index = () => {
	const { active, isInitialLoading, status } = useController();

	if (isInitialLoading) return <CenteredCircularProgress />;

	// TODO: https://samdesk.atlassian.net/browse/DEV-5035 this should probably be it's own route??
	if (status === "success" && !active) return <DisabledAccount />;

	return (
		<Suspense fallback={<CenteredCircularProgress />}>
			<Wrapper
				apiKey={window.SAM._env_.REACT_APP_GOOGLE_API || ""}
				libraries={["places", "geometry", "visualization"]}
				render={render}
				version="3.58"
			>
				<SamdeskIntercomProvider>
					<Box>
						<NavBar />
						{/* screen height container so content under navbar scrolls and navbar stays on screen */}
						<Box id="outlet-wrapper" sx={wrapperSx}>
							<EnhancedOutlet />
						</Box>
					</Box>
				</SamdeskIntercomProvider>

				{/* this modal has to be inside of the Router wrapper to be able to use `useParams` */}
				<Suspense fallback={<CenteredCircularProgress />}>
					<IncidentViewModal />
					<SearchModal />
				</Suspense>
			</Wrapper>
		</Suspense>
	);
};

export default withErrorBoundary(Index);

/**
 * render props is the Google recommended way to render different states for a map
 * if status is success then it renders the children of the wrapper
 */
const render = (status: Status): ReactElement => {
	if (status === Status.FAILURE) return <ErrorComponent />;
	return <CircularProgress />;
};

const wrapperSx = {
	maxHeight: `calc(100vh - ${layout.navBar.height}px)`,
	overflowX: "hidden",
	overflowY: "auto",
};
