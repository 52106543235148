import { Box, Typography } from "@mui/material";
import theme from "theme";

interface Props {
	headerMessage: string;
	subMessage?: string;
	subMessageOnClick?: () => void;
}

const EmptyStateBox = ({
	headerMessage,
	subMessage,
	subMessageOnClick,
}: Props) => {
	const showSubMessageWithOnClick = subMessage && subMessageOnClick;
	const showSubMessageNoOnClick = subMessage && !subMessageOnClick;
	return (
		<Box sx={emptyStateBoxSx}>
			<Box sx={emptyStateBoxHeaderMessageSx}>{headerMessage}</Box>
			{showSubMessageWithOnClick && (
				<Typography onClick={subMessageOnClick} sx={emptyStateBoxLinkSx}>
					{subMessage}
				</Typography>
			)}
			{showSubMessageNoOnClick && (
				<Typography component="p" sx={emptyStateBoxSubMessageSx}>
					{subMessage}
				</Typography>
			)}
		</Box>
	);
};

export default EmptyStateBox;

const emptyStateBoxSx = {
	border: `dashed ${theme.palette.grey[500]} 1px`,
	borderRadius: "5px",
	padding: "25px",
	textAlign: "center",
};

const emptyStateBoxHeaderMessageSx = {
	fontSize: "h3.fontSize",
};

const emptyStateBoxLinkSx = {
	"&:hover": {
		cursor: "pointer",
	},
	color: "primary.light",
	fontSize: "p2.fontSize",
	textDecoration: "none",
};

const emptyStateBoxSubMessageSx = {
	fontSize: "p2.fontSize",
};
