import { useMixpanel } from "react-mixpanel-browser";

import deepCopy from "./deepCopy";

interface FollowupClickData {
	incident_id: string;
	type: string;
	text: string;
	is_new_dash?: boolean;
}

interface LogToMixPanelData {
	after?: string;
	before?: string;
	incident_id?: string;
	is_new_dash?: boolean;
	keywords?: string[];
	locations?: {
		_id: string;
		key: string;
	}[];
	source?: string;
	stream_id?: string;
	summary?: string;
	token?: string;
}

type MixpanelData = FollowupClickData | LogToMixPanelData;

const useLogToMixPanel = () => {
	const mixpanel = useMixpanel();

	const logToMixpanel = (event: string, data: MixpanelData) => {
		if (mixpanel.config?.token) {
			const dataCopy = deepCopy(data);
			dataCopy.is_new_dash = true;
			mixpanel.track(event, dataCopy);
		} else {
			console.info(`Event ${event} skipped tracking`);
		}
	};

	return { logToMixpanel };
};

export default useLogToMixPanel;
