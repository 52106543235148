import { PropsWithChildren } from "react";
import { IntercomProvider } from "react-use-intercom";
import { useActiveAccount } from "./useActiveAccount";

export function SamdeskIntercomProvider({ children }: PropsWithChildren<{}>) {
	const { intercom, user, account, personName } = useActiveAccount();

	return (
		<IntercomProvider
			autoBootProps={{
				company: { companyId: account?._id || "" },
				hideDefaultLauncher: true,
				name: personName,
				userHash: intercom?.user_hash || "",
				userId: user?._id || "",
			}}
			appId={window.SAM._env_.REACT_APP_INTERCOM_APP_ID}
			autoBoot
			onHide={() => {
				window.Intercom("shutdown");
				setTimeout(() => {
					window.Intercom("boot");
				}, 50);
			}}
		>
			{children}
		</IntercomProvider>
	);
}
