import { Close } from "@mui/icons-material";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { SyntheticEvent } from "react";
import { SnackType, useSnackbarStore } from "stores/snackbarStore";

const SnackBar = () => {
	const {
		setSnackMessage,
		setSnackOpen,
		snackDuration,
		snackMessage,
		snackOpen,
		snackType,
	} = useSnackbarStore();

	const handleClose = (event: Event | SyntheticEvent, reason?: string) => {
		if (reason === "clickaway") return;
		setSnackOpen(false);
	};

	if (!snackType) return null;

	return (
		<Snackbar
			TransitionProps={{ onExited: () => setSnackMessage(undefined) }}
			anchorOrigin={{ horizontal: "right", vertical: "top" }}
			autoHideDuration={snackDuration}
			onClose={handleClose}
			open={snackOpen}
		>
			<MuiAlert
				action={<Close onClick={handleClose} sx={closeIconSx} />}
				severity={snackType}
				sx={innerSnackSx(snackType)}
				variant="filled"
			>
				{snackMessage}
			</MuiAlert>
		</Snackbar>
	);
};

export default SnackBar;

/** Style */

const closeIconSx = {
	"&:hover": {
		cursor: "pointer",
	},
	alignSelf: "center",
	color: "text.primary",
	fontSize: "24px",
	marginBottom: "4px",

	padding: "3px",
};

const innerSnackSx = (snackType: SnackType) => ({
	backgroundColor:
		(snackType === "success" && "primary.main") ||
		(snackType === "error" && "warning.critical") ||
		(snackType === "warning" && "warning.main") ||
		"eventType.blue",
	color: "text.primary",
	width: "100%",
});
