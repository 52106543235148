import { Close } from "@mui/icons-material";
import {
	Box,
	IconButton,
	Tab as MuiTab,
	Skeleton,
	Typography,
} from "@mui/material";
import { Tab as TabType } from "autoGenSubTypes";
import layout from "common/layoutDefinitions";
import { useRef } from "react";
import theme from "theme";
import { getTabPath } from "utils/tabUtils";

import IncidentSubLabel from "./IncidentSubLabel";
import TabMenu from "./TabMenu";
import useControllerTab from "./useControllerTab";

interface Props {
	isCurrentTab: boolean;
	isOverflowComponent?: boolean;
	tab: TabType;
}

const Tab = ({ isCurrentTab, isOverflowComponent, tab }: Props) => {
	const {
		tabLabels,
		handleCloseTab,
		incident,
		isFetchingDashboard,
		isLoadingDeleteDashboardTab,
		navigate,
		tabKey,
		totalMissedAlerts,
	} = useControllerTab(tab);
	const tabRef = useRef(null);

	return (
		<Box
			ref={tabRef}
			sx={tabContainerSx(isCurrentTab, isOverflowComponent)}
			title={tabLabels.label}
		>
			<Box
				onClick={() => navigate(getTabPath(tab))}
				sx={tabButtonsWrapperSx(isCurrentTab)}
			>
				<MuiTab
					label={
						<Box sx={labelWrapperSx}>
							{tab.__typename === "IncidentTab" ?
								<IncidentSubLabel eventTypes={incident?.types} />
							:	<Typography sx={tabSubLabelSx} variant="caption2">
									{tabLabels.subLabel}
								</Typography>
							}
							{tab ?
								<Typography noWrap sx={tabLabelSx} variant="p2">
									{tabLabels.label}
								</Typography>
							:	<Skeleton sx={tabLabelSkeletonSx} variant="text" />}
						</Box>
					}
					sx={tabButtonSx(isCurrentTab)}
				/>
			</Box>

			{/* Alert tab (missed count, menu options), other tabs (close tab button) */}
			<Box sx={otherTabsSx}>
				{tab.__typename === "AlertsTab" &&
					totalMissedAlerts > 0 &&
					tab.key !== tabKey && (
						<Typography
							sx={countBadgeSx(isOverflowComponent)}
							variant="caption1"
						>
							{totalMissedAlerts < 100 ? totalMissedAlerts : "99+"}
						</Typography>
					)}
				{tab.__typename === "AlertsTab" ?
					<TabMenu
						alertsData={tab.alerts_tab_data}
						ref={tabRef}
						tabKey={tab.key}
					/>
				:	<IconButton
						disabled={isLoadingDeleteDashboardTab || isFetchingDashboard}
						onClick={handleCloseTab}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			</Box>
		</Box>
	);
};

export default Tab;

/** STYLES */

const tabSubLabelSx = {
	color: "text.secondary",
	textTransform: "none",
};

const tabLabelSx = {
	textTransform: "none",
};

const tabLabelSkeletonSx = {
	fontSize: "1rem",
	width: "4rem",
};

const tabButtonsWrapperSx = (isCurrentTab: boolean) => ({
	alignItems: "center",
	cursor: isCurrentTab ? "default !important" : "pointer",
	display: "flex",
	flexDirection: "row",
	overflowX: "hidden",
	width: "100%",
});

const otherTabsSx = {
	alignItems: "center",
	display: "flex",
	flexDirection: "row",
};

const tabButtonSx = (isCurrentTab: boolean) => ({
	alignItems: "baseline",
	cursor: isCurrentTab ? "default !important" : "pointer",
	opacity: 1,
	padding: "0.25rem 0.5rem",
	textAlign: "left",
	width: "100%",
});

const tabContainerSx = (
	isCurrentTab: boolean,
	isOverflowComponent?: boolean
) => ({
	"&:hover": {
		backgroundColor:
			isCurrentTab ? theme.palette.grey[900] : theme.palette.grey[800],
	},
	alignItems: "center",
	background:
		isCurrentTab ? theme.palette.grey[900] : theme.palette.background.default,
	border:
		isOverflowComponent ? "none" : (
			`${layout.navBar.border}px solid ${theme.palette.border.main}`
		),
	borderBottom: `${layout.navBar.border}px solid ${theme.palette.grey[900]}`,
	borderRadius: isOverflowComponent ? "0" : "4px 4px 0 0",
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	marginRight: isOverflowComponent ? "0" : "0.5rem",
	maxHeight: "50px",
	maxWidth: isOverflowComponent ? "200px" : "240px",
	minWidth: isOverflowComponent ? "240px" : "200px",
	padding: `0 0.5rem ${layout.navBar.border}px 0.5rem`,
	position: "relative",

	transition: "background 0.3s",
});

const labelWrapperSx = {
	display: "flex",
	flexDirection: "column",
	justifyContent: "space-between",
	p: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		textTransform: "none",
		whiteSpace: "nowrap",
	},

	width: "100%",
};

const countBadgeSx = (isOverflowComponent?: boolean) => ({
	alignItems: "center",
	background: theme.palette.primary.main,
	borderRadius: "100px",
	color: theme.palette.background.default,
	display: "flex",
	justifyContent: "center",
	minHeight: "18px",
	minWidth: "18px",
	position: isOverflowComponent ? "relative" : "absolute",
	right: isOverflowComponent ? "0.5rem" : "-5px",
	top: isOverflowComponent ? "inherit" : "-10px",
});
