import isPropValid from "@emotion/is-prop-valid";
import { PropsWithChildren } from "react";
import { StyleSheetManager } from "styled-components";

/** As per: https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
 *
 * styled-components no longer handles prop-forwarding for us
 * and sometimes invalid HTML props try to sneak their way through
 * to the DOM from styled components or even MUI components. This
 * wrapper filters out any props that are not valid HTML props.
 * */

export function StyledComponentsManager({ children }: PropsWithChildren) {
	return (
		<StyleSheetManager shouldForwardProp={shouldForwardProp}>
			{children}
		</StyleSheetManager>
	);
}

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: unknown) {
	if (typeof target === "string") {
		// For HTML elements, forward the prop if it is a valid HTML attribute
		return isPropValid(propName);
	}
	// For other elements, forward all props
	return true;
}
