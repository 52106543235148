import { useGetPerson } from "api/queries/useGetPerson";
import useLegacyRedirect from "router/useLegacyRedirect";
import { useActiveAccount } from "utils/useActiveAccount";
import useGlobalState from "utils/useGlobalState";
import useRequiresAuth from "utils/useRequiresAuth";
import useSetupMixpanel from "utils/useSetupMixpanel";

const useController = () => {
	const { isInitialLoading, status } = useGetPerson();
	const { active } = useActiveAccount();

	useLegacyRedirect();
	useRequiresAuth();
	useGlobalState();
	useSetupMixpanel();

	return {
		active,
		isInitialLoading,
		status,
	};
};

export default useController;
